module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ming Zhu","short_name":"Ming Zhu","start_url":"/","background_color":"#ffffff","theme_color":"#ff7735","display":"standalone","icon":"src/images/mz.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"da6baae89c8b6ec70e693af5bfd8e229"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
